import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.sass']
})
export class CategoriesComponent implements OnInit {
  @Input() clasificaciones: any[];
  @Output() clasifChanged = new EventEmitter();
  arr_selected:any[]=[];
  constructor() { }

  ngOnInit() {
    console.error(this.clasificaciones);
  }

  clasifSelect(event, index?: number) {
    if(event){
     this.arr_selected.push(this.clasificaciones[index]);
    }else{
     const idx = this.arr_selected.findIndex(item => item.marMarcaId === this.clasificaciones[index].marMarcaId);
     this.arr_selected.splice(idx, 1);
    }
   this.clasifChanged.emit(
     this.arr_selected
   );
   }

}
