import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';


@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.sass']
})
export class BrandsComponent implements OnInit, OnChanges {


  @Input() brands: any[];
  @Output() brandChanged = new EventEmitter();

  arr_selected:any[]=[];
  constructor() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.arr_selected = [];
  }

  ngOnInit() {
  }


  brendSelect(event, index?: number) {
   if(event){
    this.arr_selected.push(this.brands[index]);
   }else{
    const idx = this.arr_selected.findIndex(item => item.marMarcaId === this.brands[index].marMarcaId);
    this.arr_selected.splice(idx, 1);
   }
  this.brandChanged.emit(
    this.arr_selected
  );
  }

}
