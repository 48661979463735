import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import {Product} from "../../modals/product.model";
import {CartItem} from "../../modals/cart-item";
import {ProductService} from "../shared/services/product.service";
import {CartService} from "../shared/services/cart.service";
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { SidebarMenuService } from '../shared/sidebar/sidebar-menu.service';
import { SidenavMenu } from '../shared/sidebar/sidebar-menu.model';
import { MenuService } from '../shared/services/menu.service';
import { oUser } from 'src/app/modals/user';
import { AccountService } from '../shared/services/account.service';
import { PublicService } from '../shared/services/public.service';
/*
interface oMenu {
  catDescripcion?: any;
  submenu?: any[][];
}
*/
interface oMenu {
  displayName: string,
  iconName: string,
  route: string,
  children?:any[]
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.sass']
})
export class MainComponent implements OnInit {

  mainData:any;
  contactData:any;
  user:oUser;


  public sidenavMenuItems:Array<any>;

  public currencies = ['USD', 'EUR'];
  public currency:any;
  public flags = [
    { name:'English', image: 'assets/images/flags/gb.svg' },
    { name:'German', image: 'assets/images/flags/de.svg' },
    { name:'French', image: 'assets/images/flags/fr.svg' },
    { name:'Russian', image: 'assets/images/flags/ru.svg' },
    { name:'Turkish', image: 'assets/images/flags/tr.svg' }
  ]
  public flag:any;

  products: Product[];

  indexProduct: number;
  shoppingCartItems: CartItem[] = [];

  public banners = [];

  wishlistItems  :   Product[] = [];

  public url : any;
  menu:any[];
  menufinal:oMenu[]=[];

  navItems: SidenavMenu[] = [
    {
      displayName: 'HOME',
      iconName: 'recent_actors',
      route: '/home/ls'
    },
    {
      displayName: 'PRODUCTOS',
      iconName: 'movie_filter',
      children: []
    },
    {
      displayName: 'QUIENES SOMOS',
          iconName: 'feedback',
          route: '/pages/about'
    },
    {
      displayName: 'FAQ',
          iconName: 'feedback',
          route: '/pages/faq'
    },
    {
      displayName: 'Contacto',
          iconName: 'feedback',
          route: '/pages/contact'
    }
  ];
  boolMenuRedefinido:boolean=false;

  constructor(public router: Router, 
              private cartService: CartService, 
              public sidenavMenuService:SidebarMenuService,
              public menuService: MenuService,
              private accountService :AccountService,
              private pubService: PublicService
              ) {
    this.cartService.getItems().subscribe(shoppingCartItems => this.shoppingCartItems = shoppingCartItems);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    } );
  }

  ngAfterViewInit() {
  }
  transformarrMenu( _arr:any[] = [], _arr_ret:any[], nivel:number){
    for (let i = 0; i < _arr.length; i++) {
      if (nivel==0){
           _arr_ret.push({catCategoriaId: 4,
                          catCod: _arr[i].data.catCod,
                          catDescripcion: _arr[i].data.descripcion,
                          catServicio: 1,
                          catWeb: 1,
                          submenu:[]});   
      }
      if (nivel==1){
          _arr_ret.push({catCod: _arr[i].data.catCod,
                         scaSubCategoriaId: 4,
                         scaDescripcion: _arr[i].data.descripcion});   
      }  
      if (_arr[i].children?.length > 0 ){
        this.transformarrMenu(_arr[i].children, _arr_ret[_arr_ret.length - 1].submenu, (nivel + 1));
      }    
    }
  }
  dibujaMenu(res){
    this.menu =  Array.from(res);
    for (var imenu of this.menu) {
          let submenuoriginal:any[]=Array.from(imenu.submenu);
          let submenu:oMenu[]=[];
          for (var isubmenu of submenuoriginal) {
            submenu.push({"displayName":isubmenu.scaDescripcion,
                          "iconName":"",
                          "route":"/home/products/" + (this.boolMenuRedefinido?'1-' + isubmenu.catCod:'0-' + isubmenu.scaSubCategoriaId) +"/left-sidebar"});
          }  
          let currentOmenu:oMenu = {"displayName":imenu.catDescripcion,
                                    "children":submenu,
                                    "iconName":"",
                                    "route":""};
          this.menufinal.push(currentOmenu);                       
    }
    let item1 = this.navItems.find(i => i.displayName === "PRODUCTOS");
    item1.children = this.menufinal;
  }

  ngOnInit() {
      this.currency = this.currencies[0];
      this.pubService.getMenuData().subscribe((res) => {

        if(res["checkboxMenu"]==0){
           this.boolMenuRedefinido = true;
           this.menuService.getmenu().subscribe((subres) => {
            let menu_final:[]=[];
            this.transformarrMenu(res["menu"],menu_final,0);
            this.dibujaMenu(menu_final);
          });

        }else{
          this.menuService.getmenu().subscribe((subres) => {
            this.dibujaMenu(subres.payload);
          });
        }


    });

/*
      this.menuService.getmenu().subscribe((res) => {

        this.menu =  Array.from(res.payload);
        for (var imenu of this.menu) {
              let submenuoriginal:any[]=Array.from(imenu.submenu);
              let submenu:oMenu[]=[];
              for (var isubmenu of submenuoriginal) {
                submenu.push({"displayName":isubmenu.scaDescripcion,
                              "iconName":"",
                              "route":"/home/products/" + isubmenu.scaSubCategoriaId +"/left-sidebar"});
              }  
              let currentOmenu:oMenu = {"displayName":imenu.catDescripcion,
                                        "children":submenu,
                                        "iconName":"",
                                        "route":""};
              this.menufinal.push(currentOmenu);                       
        }
        let item1 = this.navItems.find(i => i.displayName === "PRODUCTOS");
        item1.children = this.menufinal;

    })
*/

    this.user = this.accountService.getAccount();
    this.pubService.getMainData().subscribe((res) => {
          this.mainData = res
         
    });
    this.pubService.getContactData().subscribe((res) => {

      this.contactData = res;
    });

  }


  public onSalirClick(){
    this.accountService.logout();
    this.cartService.removeAll();                       
    window.location.reload();
 
  }


  public changeCurrency(currency){
    this.currency = currency;
  }
  public changeLang(flag){
    this.flag = flag;
  }

}
