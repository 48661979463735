import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from "./api.service";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
  })

export class PublicService  extends ApiService {

    constructor(private httpClient: HttpClient) {
        super(httpClient);
    
    }
    public getFortalezas() {
      return this.httpClient.get<any[]>(environment.filesUrl +'web/fortalezas.json');
    }
    
    public getContactData() {
      return this.httpClient.get<any[]>(environment.filesUrl +'web/contact.json?'+ new Date().getTime());
    }

    public getMainData() {
      return this.httpClient.get<any[]>(environment.filesUrl +'web/datos.json?'+ new Date().getTime());
    }
    
    public getAboutData() {
      return this.httpClient.get<any[]>(environment.filesUrl +'web/aboutus.json?'+ new Date().getTime());
    }

    public getFaqData() {
        return this.httpClient.get<any[]>(environment.filesUrl +'web/faq.json?'+ new Date().getTime());
    }

    public getMenuData() {
      return this.httpClient.get<any[]>(environment.filesUrl +'web/menu.json?'+ new Date().getTime());
    }

}    