import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../shared/services/product.service';
import { Product } from 'src/app/modals/product.model';
import { oUser } from 'src/app/modals/user';
import { AccountService } from '../../shared/services/account.service';
import { PublicService } from '../../shared/services/public.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  products: Product[];
  productosDestacados: Product[]=[];
  productosOfertas: Product[]=[];
  productosIngresos: Product[]=[];  
  contentLoaded = false;
  public banners = [];
  public slides = [];
  public fortalezas = [];
  public mainData:any;
  user:oUser;
  public mnuRedefinido:boolean=false;
  constructor(private productService: ProductService,  
              private accountService :AccountService,
              private pubService: PublicService) { }

  ngOnInit() {
    
    this.user = this.accountService.getAccount();

    this.pubService.getMenuData()
    .subscribe( (data) => {

      this.productService.productosDestacados(data["checkboxMenu"])
      .subscribe((res) => {
            for (var product of res.payload) {
              this.productosDestacados.push(this.productService.toProducto(product, this.user?.cliBonif || 0));
            }
        }
      );
      this.productService.productosOfertas(data["checkboxMenu"])
      .subscribe((res) => {
            for (var product of res.payload) {
              this.productosOfertas.push(this.productService.toProducto(product, this.user?.cliBonif || 0));
            }
        }
      );
      this.productService.productosIngresos(data["checkboxMenu"])
      .subscribe((res) => {
            for (var product of res.payload) {
              this.productosIngresos.push(this.productService.toProducto(product, this.user?.cliBonif || 0));
            }
        }
      )
  

     }

    );

    this.pubService.getMainData()
    .subscribe( (data) => {
      this.mainData = data;
    }
    );
    
    this.productService.getBanners()
    .subscribe( (data) => {
        this.slides = data;
    }
    );

    this.pubService.getFortalezas()
    .subscribe( (data) => {
      this.fortalezas = data;
     
    }
   );
    this.productService.getProducts()
    .subscribe(
      (product: Product[]) => {
        this.products = product
      }
    );
 

 setTimeout(() => {
      this.contentLoaded = true;
    }, 3000);
    
  }






}
